import React, { useEffect, useRef, useState } from "react"
import "./Slideshow.scss"

export const Slideshow = ({ background, images }) => {
  const [index, setIndex] = useState(0)
  const [translateValues, setTranslateValues] = useState([])
  const slideshow = useRef()
  const wrapper = useRef()

  const populateTranslateValues = (image, i) => {
    const shallowArr = translateValues
    let value
    if (i === images.length - 1) {
      value = wrapper.current.scrollWidth - slideshow.current.clientWidth
    } else {
      value = image.current.offsetLeft
    }
    shallowArr.splice(i, 1, value)
    setTranslateValues(shallowArr)
  }

  const paginateHandler = e => {
    e.preventDefault()
    if (e.target.getAttribute("direction") === "next") setIndex(index + 1)
    else setIndex(index - 1)
  }

  return (
    <>
      <img
        className="bg-blur"
        src={background[0]}
        alt={background[1]}
        style={{ overflow: "visible" }}
      />

      {images.length > 1 && index !== images.length - 1 && (
        <button
          className="paginate next"
          direction="next"
          onClick={paginateHandler}
        >
          →
        </button>
      )}
      {index > 0 && (
        <button className="paginate previous" onClick={paginateHandler}>
          ←
        </button>
      )}
      <div className="slideshow" ref={slideshow}>
        <div
          className="slideshow-wrapper"
          ref={wrapper}
          style={{ transform: `translate(-${translateValues[index]}px)` }}
        >
          {images.map((attr, i) => (
            <Image
              src={attr[0]}
              alt={attr[1]}
              title={attr[2]}
              key={attr[0]}
              index={i}
              populateTranslateValues={populateTranslateValues}
            />
          ))}
        </div>
      </div>
    </>
  )
}

const Image = ({ alt, index, populateTranslateValues, src, title }) => {
  const [loaded, setLoaded] = useState(false)
  const image = useRef()

  useEffect(() => {
    if (image.current.complete) {
      populateTranslateValues(image, index)
    }
  }, [loaded])

  return (
    <img
      src={src}
      alt={alt}
      title={title}
      ref={image}
      onLoad={() => setLoaded(true)}
    />
  )
}
