import React, { useState } from 'react'
import './Card.scss'

export const Card = ({ date, thumbnail, title, type }) => {
  const [isHovering, setHovering] = useState(false);
  const hoverHandler = () => setHovering(!isHovering)

  return (
    <div className="Card" onMouseEnter={hoverHandler} onMouseLeave={hoverHandler}>
      <p>{type}</p>
      <img className="blur-bg" src={thumbnail} alt={title} style={{ opacity: isHovering ? 1 : 0 }} />
      <img src={thumbnail} alt={title} />
      <p>{date}</p>
      <h2>{title}</h2>
    </div>
  )
}
