import React from "react"
import jsonp from "jsonp"
import "./Contact.scss"

const onClickHandler = e => {
  e.preventDefault()
  const email = document.querySelector("#email")
  const label = document.querySelector("#label")
  let url = `https://gmail.us20.list-manage.com/subscribe/post-json?u=df132e1665b6e87ec05601c6b&amp;id=bd27f571dd&EMAIL=${email.value}`
  jsonp(url, { param: "c" }, (err, { result, msg }) => {
    console.log(result, msg)
    if (result === "success") {
      email.value = ""
      label.textContent = msg
      label.style.color = "green"
    } else {
      label.textContent = "Check your email and try again"
      label.style.color = "red"
    }
  })
}

export const Contact = ({ closeOnClick, isOpen, style }) => {
  return (
    <div className="Contact" style={{ ...style, height: isOpen ? 120 : 0 }}>
      <div className="container">
        <section style={{ minWidth: "140px" }}>
          <div>
            <a href="mailto:info@discrit.net">info@discrit.net</a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/discritatl"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/discrit_atl"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </div>
        </section>
        <section>
          <label id="label">Subscribe to our newsletter</label>
          <input id="email" type="text" placeholder="Your email" />
          <div>
            <span onClick={onClickHandler}>Subscribe</span>
          </div>
        </section>
        <div className="close" onClick={closeOnClick}>
          X
        </div>
      </div>
    </div>
  )
}
