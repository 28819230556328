import React from "react"
import jsonp from "jsonp"
import "./Footer.scss"

const onClickHandler = e => {
  e.preventDefault()
  const email = document.querySelector("#footer-email")
  const label = document.querySelector("#footer-label")
  let url = `https://gmail.us20.list-manage.com/subscribe/post-json?u=df132e1665b6e87ec05601c6b&amp;id=bd27f571dd&EMAIL=${email.value}`
  jsonp(url, { param: "c" }, (err, { result, msg }) => {
    console.log(result, msg)
    if (result === "success") {
      email.value = ""
      label.textContent = msg
      label.style.color = "lightgreen"
      label.style["font-size"] = "2rem"
    } else {
      label.textContent = "Check your email and try again"
      label.style.color = "red"
      label.style["font-size"] = "1.7rem"
    }
  })
}

export const Footer = () => {
  return (
    <footer>
      <div className="container">
        <section>
          <h1>
            <label id="footer-label">Stay connected!</label>
          </h1>
          <input id="footer-email" type="text" placeholder="Your email" />
          <button onClick={onClickHandler}>Subscribe</button>
        </section>

        <section>
          <div>
            <div>
              <a href="/about">About</a>
            </div>
            <div>
              <a href="/programs">Programs</a>
            </div>
            <div>
              <a href="mailto:info@discrit.net">Email</a>
            </div>
            <div>
              <a
                href="https://www.facebook.com/discritatl"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/discrit_atl"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </section>
      </div>

      <span>
        Site by <a href="mailto:steffenssornpao@gmail.com">Steffen Sornpao</a>
      </span>
    </footer>
  )
}
