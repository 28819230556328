import React from "react"
import "./Upcoming.scss"
import { transformLinks } from "../../utils"

export const Upcoming = ({
  data: { date, description, thumbnail, time, title, type },
}) => {
  time = transformLinks(time)
  description = transformLinks(description)
  return (
    <div className="Upcoming">
      <h1>Upcoming</h1>
      <div className="container">
        <img src={thumbnail} alt={title} />
        <div className="details">
          <span className="date">{date}</span>
          <div>
            <p>{type}</p>
            <h2>{title}</h2>
            <p dangerouslySetInnerHTML={{ __html: time }} />
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      </div>
    </div>
  )
}
