export const transformLinks = text => {
  const splitText = text.split(/[{}]+/)
  const textToReturn = splitText.map(el => {
    let linkToRender
    console.log(el[0] === "[")
    if (el[0] === "[") {
      const link = el.split(/[\[\]]+/)
      linkToRender = `<a href="${link[2]} target="_blank" rel="noopener noreferrer" style="text-decoration: underline">${link[1]}</a>`
      return linkToRender
    } else return el
  })
  console.log(textToReturn)
  return textToReturn.join("")
}
