import React from 'react'
import { Card } from '../'
import './Past.scss'

export const Past = ({ programs }) => {
  return (
    <div className='Past' >
      <h1>Past</h1>
      <div className="cards">
        {programs.map(({ frontmatter: el }) => (
          <a href={`/programs/${el.path}`} key={el.title}>
            <Card
              date={el.date}
              thumbnail={el.thumbnail}
              title={el.title}
              type={el.type} />
          </a>
        )
        )}
      </div>
    </div>
  )
}