import React from "react"
import { Link } from "gatsby"
import anime from "animejs"
import "./Nav.scss"

export const Nav = ({ contactOnClick }) => {
  const hoverHandler = ({ target: { id }, type }) => {
    const entering = type === "mouseover"
    const x = entering ? -36 : 0
    const target = entering ? `#${id}` : "nav .link span"
    const opacity = entering ? 1 : 0

    anime({
      targets: target,
      translateX: x,
      easing: "easeOutCirc",
    })
    anime({
      targets: `${target} .arrow`,
      easing: "easeOutCirc",
      opacity: opacity,
    })
  }

  return (
    <nav>
      <Link
        className="link"
        to="/about"
        onMouseOver={hoverHandler}
        onMouseLeave={hoverHandler}
      >
        <span id="About">
          About<span className="arrow">←</span>
        </span>
      </Link>
      <Link
        className="link"
        to="/programs"
        onMouseOver={hoverHandler}
        onMouseLeave={hoverHandler}
      >
        <span id="Programs">
          Programs<span className="arrow">←</span>
        </span>
      </Link>
      <div
        className="link"
        onClick={() => contactOnClick("open")}
        onMouseOver={hoverHandler}
        onMouseLeave={hoverHandler}
      >
        <span id="Contact">
          Contact<span className="arrow">←</span>
        </span>
      </div>
    </nav>
  )
}
