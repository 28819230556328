import React, { useMemo } from 'react'
import './Orb.scss'

export const Orb = ({ size, style }) => {

  const circles = [];
  const amount = 12

  for (let i = 1; i <= amount; i++) {
    const size = `calc(10% * ${i + Math.random()})`;
    const color = Math.floor(Math.random() * (360 - 1) + 1)

    circles.push(
      <div className="orb" key={i}
        style={{
          animation: `grow ${Math.floor(Math.random() * (20 - 5) + 5)}s infinite alternate`,
          boxShadow: `0 0 50px 10px hsla(${color}, 75%, 75%, .75)`,
          height: size,
          width: size,
        }} />
    )
  }

  return (
    <div className="Orb">
      <div className="container">
        {useMemo(() => circles.map(el => el), [])}
      </div>
    </div>
  )
}