import React, { useState } from "react"
import { Contact, Nav } from "../"
import "./Header.scss"

export const Header = () => {
  let [contactDrawerIsOpen, setContactDrawerIsOpen] = useState(false)

  const toggleContactDrawer = state => {
    if (state === "open") {
      setContactDrawerIsOpen(true)
    } else {
      setContactDrawerIsOpen(false)
    }
  }

  return (
    <>
      <Contact
        isOpen={contactDrawerIsOpen}
        closeOnClick={toggleContactDrawer}
      />
      <header>
        <a href="/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.001 360.666">
            <g>
              <path
                className="cls"
                d="M10.705,7.51L23.5,5
		c0,0,18.497,109.363,22.582,133.515s25.086,148.32,25.086,148.32l-12.503,2.499L10.705,7.51z"
              />
              <path
                className="cls"
                d="M4.667,291.501v-25.168c0,0,5.5,0,11.5,0
		s14.666,0,14.666,0v25.168H4.667z"
              />
              <path
                className="cls"
                d="M70.499,56.333L70.165,5h67
		c0,0,157.333,3.833,157.333,173.333C294.499,356.167,137.142,355,137.142,355L5.666,356.167V306l123.499-1.667
		c0,0,97.597,5.5,97.547-126C226.666,57.333,133.5,56.5,133.5,56.5L70.499,56.333z"
              />
            </g>
          </svg>
        </a>
        <Nav contactOnClick={() => toggleContactDrawer("open")} />
      </header>
    </>
  )
}
