import React, { useEffect } from "react"
import { Orb, Nav } from "../"
import anime from "animejs"
import "./Splash.scss"

export const Splash = ({ toggleContactDrawer }) => {
  useEffect(() => {
    setTimeout(pageLoadAnimation, 200)
  }, [])

  const title = ["D", "I", "S", "C", "R", "I", "T"]

  return (
    <div className="Splash">
      <div className="logo-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.001 360.666">
          <g>
            <path
              className="cls"
              d="M10.705,7.51L23.5,5
		c0,0,18.497,109.363,22.582,133.515s25.086,148.32,25.086,148.32l-12.503,2.499L10.705,7.51z"
            />
            <path
              className="cls"
              d="M4.667,291.501v-25.168c0,0,5.5,0,11.5,0
		s14.666,0,14.666,0v25.168H4.667z"
            />
            <path
              className="cls"
              d="M70.499,56.333L70.165,5h67
		c0,0,157.333,3.833,157.333,173.333C294.499,356.167,137.142,355,137.142,355L5.666,356.167V306l123.499-1.667
		c0,0,97.597,5.5,97.547-126C226.666,57.333,133.5,56.5,133.5,56.5L70.499,56.333z"
            />
          </g>
        </svg>

        <div className="title title-desktop">
          {title.map((ltr, i) => (
            <span key={i}>{ltr}</span>
          ))}
        </div>

        <div className="title title-mobile">
          {title.map((ltr, i) => (
            <span key={i}>{ltr}</span>
          ))}
        </div>
      </div>

      <Orb />

      <Nav contactOnClick={toggleContactDrawer} />

      <div className="scroll-alert">
        <span>↓</span>
      </div>

      <div className="description">
        <p>
          <span>An art education platform</span>
        </p>
        <p>
          <span>fostering experimental thinking and</span>
        </p>
        <p>
          <span>debate in contemporary art and culture.</span>
        </p>
      </div>
    </div>
  )
}

const pageLoadAnimation = () => {
  anime({
    // prevents page flash before animation
    targets: "#home",
    opacity: 1,
  })
  anime({
    targets: ".Orb",
    opacity: [0, 1],
    translateX: [-600, 0],
    rotate: [-360, 1], // ? freaks out when trying to animate to 0
    easing: "easeOutQuart", // ? easeOutExpo causes a weird flash once the animation finishes (specifically attributed to rotate)
    duration: 4000,
  })
  anime({
    targets: ".title-desktop > span",
    translateX: [205, 0],
    easing: "easeOutExpo",
    delay: (el, i, l) => (l - i) * 250 + 500,
    duration: 2000,
  })
  anime({
    targets: ".title-mobile > span",
    translateY: [95, 0],
    easing: "easeOutExpo",
    delay: (el, i, l) => (l - i) * 250 + 500,
    duration: 2000,
  })
  anime({
    targets: [".Splash > nav > .link > span", ".description > p > span"],
    translateY: [100, 0],
    easing: "easeOutExpo",
    delay: anime.stagger(250),
    duration: 2000,
  })
  anime({
    targets: ".cls",
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: "easeInOutSine",
    duration: 2000,
    delay: function(el, i) {
      return i * 250
    },
  })
  setTimeout(() => {
    anime({
      targets: ".scroll-alert > span",
      keyframes: [{ translateY: [-60, 0] }, { opacity: [1, 0] }],
      duration: 1500,
      easing: "linear",
      loop: true,
    })
  }, 3000)
}
