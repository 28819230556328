import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

export const SEO = ({ author, description, keywords, lang, meta, title }) => (
  <Helmet
    htmlAttributes={{ lang }}
    title={title}
    titleTemplate={`%s | ${description}`}
    meta={[
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: author,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ]
      .concat(
        keywords.length > 0
          ? {
            name: `keywords`,
            content: keywords.join(`, `),
          }
          : []
      )
      .concat(meta)}
  />
)

SEO.defaultProps = {
  author: ``,
  description: ``,
  keywords: [],
  lang: `en`,
  meta: [],
  title: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}